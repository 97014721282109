<template>
  <div>
    <b-form inline>
      <b-input-group class="mr-sm-2">
        <flat-picker
          :config="config"
          class="form-control datepicker"
          v-model="iScheduledTrack.date"
          @on-change="formUpdated = true"
          :disabled="!editEnable"
        >
        </flat-picker>
      </b-input-group>

      <b-form-select
        :id="selectTrackId"
        v-model="iScheduledTrack.track"
        class="mb-3 mr-sm-2 mb-sm-2 mt-2"
        :options="selectTrackOptions"
        @change="formUpdated = true"
        :disabled="!editEnable"
      >
        <template #first>
          <b-form-select-option :value="null"></b-form-select-option>
        </template>
      </b-form-select>

      <b-button v-if="formUpdated && editEnable" variant="primary" @click="saveScheduledTrack(item)"><i class="fas fa-save"></i></b-button>
      <b-button v-if="editEnable" variant="warning" @click="deleteScheduledTrack(iScheduledTrack, item)"><i class="fas fa-trash"></i></b-button>
    </b-form>
  </div>
</template>

<script>
import flatPicker from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: "ProjectFormTrack",
  components : {
    flatPicker
  },
  data () {
    return {
      formUpdated:false,
      config: {
        allowInput: true,
        minDate: this.project.startDate,
        maxDate: this.project.endDate
      }
    }
  },
  props : [
    'scheduledTrack',
    'tracks',
    'project',
    'item',
    'editEnable'
  ],
  mounted() {
  },
  computed: {
    selectTrackOptions () {
      let options= [];
      this.tracks.forEach(element => {
        let newelement = {};
        newelement.value = element['@id']
        newelement.text = element.name
        options.push(newelement);
      })
      return options
    },
    iScheduledTrack () {
      return this.scheduledTrack
    },
    projectStartDate () {
      return this.project.startDate
    },
    selectTrackId () {
      return 'selectTrack' + this.item
    }
  },
  methods : {
    deleteScheduledTrack (scheduledTrack, item) {
      this.$emit('deleteScheduledTrack', {scheduledTrack, item})
    },
    saveScheduledTrack (item) {
      this.$emit('saveScheduledTrack', item)
      //TODO : Valider le bon retour
      this.formUpdated = false
    },
  }
}
</script>

<style scoped>
.form-control:disabled, .custom-select:disabled {
  background-color: white;
}
</style>
