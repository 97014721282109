<template>
  <div>
    <b-container fluid class="mt-7">
      <div>
        <b-card
          class="col-xl-12 col-lg-12 col-sm-12"
        >
          <b-row>
            <b-col class="col-8">
              <h1>Projets</h1>
              <p>Liste des projets</p>
            </b-col>
            <b-col class="col-4 text-right" align-h="end">
              <b-row align-h="end">
                <b-link @click="triggerAddProject()">
                  <i class="fas fa-plus"></i> Ajouter
                </b-link>
              </b-row>
              <b-row align-h="end">
                <b-form-checkbox v-model="showAction" name="check-button" @change="showActionColumn()" size="sm" switch>Edit</b-form-checkbox>
              </b-row>

            </b-col>
          </b-row>

          <b-table
            :items="projects"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :busy="isBusy"
            ref="projectsTable"
            responsive
            :small="tableSmall"
          >

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(name)="data">
              <b-link @click="triggerShowProject(data.item)">
                {{data.value}}
              </b-link>
            </template>

            <template #cell(actions)="data">
              <b-link @click="triggerEditProject(data.item)"><b-icon icon="pencil"></b-icon></b-link>
              <b-link @click="triggerDeleteProject(data.item)" class="text-warning pl-1"><b-icon icon="trash"></b-icon></b-link>
            </template>

          </b-table>
        </b-card>
      </div>
    </b-container>
    <b-modal
      id="modalProjectForm"
      :title="formTitle"
      ref="map-form-modal"
      v-model="modalShow"
      @hidden="modalHiddenAction"
      hide-footer
      size="xl"
    >
      <b-form
        @submit.prevent="triggerSubmitForm()"
      >
        <b-row>
          <b-col class="col-6">
            <label for="inputName">Nom</label>
            <b-form-input id="inputName" class="form-control" v-model="project.name" :disabled="!formActive"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-6">
            <label>Start date </label>
            <flat-picker
              class="form-control"
              :config="configs.start"
              v-model="project.startDate"
              @on-change="onStartChange"
              :disabled="!formActive"
            >
            </flat-picker>
          </b-col>
          <b-col class="col-6">
            <label>End date </label>
            <flat-picker
              class="form-control"
              :config="configs.end"
              v-model="project.endDate"
              @on-change="onEndChange"
              :disabled="!formActive"
            >
            </flat-picker>
          </b-col>
        </b-row>

        <b-row align-h="end" class="mr-2" v-if="formActive">
          <b-button @click="$bvModal.hide('modalProjectForm')" class="btn btn-secondary mt-2" variant="secondary">
            Annuler
          </b-button>
          <b-button type="Submit" class="btn btn-primary mt-2" variant="primary">{{ projectFormButtonSubmitName }}
          </b-button>
        </b-row>
      </b-form>
      <hr>
      <b-row v-if="formActive">
        <b-col>
          <b-button @click="triggerAddScheduledTrack"><i class="fas fa-plus"></i> Ajouter un parcours</b-button>

        </b-col>
      </b-row>

      <b-row v-for="(scheduledTrack, key) in editedScheduledTracks" :key="key">
        <b-col>
          <projectFormTrack
            :scheduledTrack="scheduledTrack"
            :tracks="tracks"
            :project="project"
            :item="key"
            :editEnable="editEnable"
            @deleteScheduledTrack="deleteScheduledTrack"
            @saveScheduledTrack="saveScheduledTrack"
          >
          </projectFormTrack>
        </b-col>
      </b-row>

      <b-row v-if="!formActive">
        <b-col>
          <b-button @click="editEnable=true"><i class="fas fa-pencil"></i> Mettre à jour</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import projectFormTrack from "../components/ProjectFormTrack";

export default {
  name: "Projects",
  components : {
    flatPicker,
    projectFormTrack,
  },
  data: () => {
    return {
      showAction: false,
      sortBy: 'startDate',
      sortDesc: false,
      isBusy: false,
      fields: [
        {key: 'name', label:'Nom', Sortable:true},
        {key: 'startDate', label:'Début', Sortable:true,
          formatter : (data, key, item) => {
            let date=new Date(data)
            return date.getDate() + "/" + (date.getMonth()+1) + "/" + date.getFullYear()
        }},
        {key: 'endDate', label:'Fin', Sortable:true,
          formatter : (data, key, item) => {
            let date=new Date(data)
            return date.getDate() + "/" + (date.getMonth()+1) + "/" + date.getFullYear()
        }},
        {key: 'scheduledTracks', label: 'Nb de parc.', Sortable:false,
          formatter : (data, key, item) => {
            return data ? data.length : 0
        }}
      ],
      actionsField: [{key: 'actions', label: '', Sortable: false}],
      modalShow : false,
      project: {
        name:'',
        startDate:'',
        endDate:'',
      },
      editedScheduledTracks:[],
      editedProject:'',
      editedProjectId:0,
      configs : {
        start: {
          minDate: null,
          maxDate: null
        },
        end: {
          minDate: null
        },
      },
      editEnable:false,
      addEnable:false,
      tableSmall: true,
      templateScheduledTrack:{
        date:'',
        isDone:false,
        track:'',
        project:''
      }
    }
  },
  mounted () {
    this.loadProjects()
    this.loadTracks()
  },
  computed : {
    ...mapGetters({
      projects : 'projects/projects',
      tracks : 'tracks/tracks',
      getTrackByAtId : 'tracks/getTrackByAtId',
      scheduledTracks: 'scheduledTracks/scheduledTracks'
    }),
    formTitle () {
      return this.editEnable ? 'Mise à jour du projet' : 'Création du projet';
    },
    projectFormButtonSubmitName () {
      return this.editEnable ? 'Mettre à jour' : 'Créer'
    },
    formActive () {
      return this.editEnable || this.addEnable
    }
  },
  methods: {
    ...mapActions({
      loadProjects : 'projects/loadProjects',
      addProject: 'projects/addProject',
      updateProject: 'projects/updateProject',
      deleteProject: 'projects/deleteProject',
      loadTracks : 'tracks/loadTracks',
      loadScheduledTracks: 'scheduledTracks/loadScheduledTracks',
      removeScheduledTrack: 'scheduledTracks/removeScheduledTrack',
      clearScheduledTracks: 'scheduledTracks/clearScheduledTracks',
      updateScheduledTrack: 'scheduledTracks/updateScheduledTrack',
      createScheduledTrack: 'scheduledTracks/createScheduledTrack',
    }),
    showActionColumn: function () {
      this.$nextTick(function() {
        this.showAction ? this.fields.push(this.actionsField) : this.fields.pop();
      })
    },
    triggerAddProject () {
      this.editEnable = false
      this.addEnable = true
      this.modalShow = true
    },
    openModal (project) {
      this.project.name = project.name
      this.project.startDate = project.startDate
      this.project.endDate = project.endDate

      this.editedProject = project['@id']
      this.editedProjectId = project.id

      this.loadScheduledTracks(project)
        .then(() => {
          for(const sc in this.scheduledTracks){
            let tempObj={
              date: '',
              track: ''
            }
            tempObj.date = this.scheduledTracks[sc].date
            tempObj.track = this.scheduledTracks[sc].track
            tempObj.isDone = this.scheduledTracks[sc].isDone
            tempObj.project = this.scheduledTracks[sc].project
            tempObj.id = this.scheduledTracks[sc].id
            this.editedScheduledTracks.push(tempObj)
          }
        })
    },
    triggerShowProject (project) {
      this.modalShow = true

      this.openModal(project)
    },
    triggerEditProject (project) {
      this.editEnable = true
      this.modalShow = true

      this.openModal(project)

    },
    triggerDeleteProject (project) {
      this.deleteProject(project.id)
      .then((response) => {
        if(response === 204) {
          this.$refs.projectsTable.refresh()
          this.notif('success', 'Projet supprimé')
        } else {
          this.notif('warning', 'Erreur lors de la suppression du projet (success error)')
        }
      })
      .catch((error)=>{
        this.notif('warning', 'Erreur lors de la suppression du projet warning error')
      })
    },
    triggerSubmitForm () {
      if(this.editEnable){
        this.updateProject({project: this.project, projectId: this.editedProjectId})
        .then((response) => {
          if(response === 200){
            this.$refs.projectsTable.refresh()
            this.notif('success','Parcours mis à jour')
          } else {
            this.notif('warning', 'Erreur lors de la mise à jour du parcours')
          }
        })
        .catch( () => this.notif('warning', 'Erreur lors de la mise à jour du parcours') )
      }else{
        this.addProject(this.project)
          .then((response) => {
            if(response === 201){
              this.$refs.projectsTable.refresh()
              this.modalShow=false
            }
          })
      }

    },
    triggerAddScheduledTrack () {
      this.templateScheduledTrack.project = this.editedProject
      this.editedScheduledTracks.push(JSON.parse(JSON.stringify(this.templateScheduledTrack)));
    },
    modalHiddenAction () {
      this.clearForm()
    },
    clearForm () {
      this.editEnable = false;
      this.addEnable = false;

      this.project.name = '';
      this.project.endDate = '';
      this.project.startDate = '';

      this.editedScheduledTracks = [];
      this.editedProject = '';

      this.clearScheduledTracks();
    },
    onStartChange(selectedDates, dateStr, instance) {
      this.configs.end.minDate = dateStr
    },
    onEndChange(selectedDates, dateStr, instance) {
      this.configs.start.maxDate = dateStr
    },
    deleteScheduledTrack(payload) {
      if(payload.scheduledTrack.id){
        this.removeScheduledTrack(payload.scheduledTrack.id)
          .then((response) => {
            if(response === 204) {
              this.editedScheduledTracks = this.editedScheduledTracks.filter(el => el.id !== payload.scheduledTrack.id)
              this.loadProjects().then(()=>{
                this.$refs.projectsTable.refresh()
              })
            }
          })
      } else {
        this.editedScheduledTracks.splice(payload.item,1);
      }

    },
    saveScheduledTrack(item){
      if(this.editedScheduledTracks[item].id) {
        let tempSc = JSON.parse(JSON.stringify(this.editedScheduledTracks[item]))
        delete tempSc.id
        this.updateScheduledTrack({scheduledTrackId: this.editedScheduledTracks[item].id ,data:tempSc} )
          .then((response) => {
            if(response === 200){
              this.loadProjects().then(()=>{
                this.$refs.projectsTable.refresh()
              })
              this.notif('success', 'Parcours mis à jour')
              return response
            } else {
              this.notif('warning', 'Erreur lors de la mise à jour')
            }
          })
          .catch((error) => {
            this.notif('warning', 'Erreur lors de la mise à jour')
          })
      } else {
        this.createScheduledTrack(this.editedScheduledTracks[item])
          .then((response) => {
            if(response === 201){
              this.loadProjects().then(()=>{
                this.$refs.projectsTable.refresh()
              })
              this.notif('success', 'Parcours mis à jour')
            }else {
              this.notif('warning', 'Erreur lors de la mise à jour')
            }
          })
          .catch((error) => {
            this.notif('warning', 'Erreur lors de la mise à jour')
          })
      }
    },
    notif(status, message) {
      this.$notify({verticalAlign: 'top', horizontalAlign: 'right', message: message, type: status})
    },
  }
}
</script>

<style scoped>
  .form-control:disabled {
    background-color: white;
  }
</style>
